import { Box } from '@mui/material'
import React from 'react'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

const RDU = () => {
  const formUrl = `https://forms.gle/BAvR4M33bvHzdiJz8`

  useRecordPageDuration()
  useScrollDepthTracker()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        src={formUrl}
        width="100%"
        height="100%"
        title="Google Form - RDU"
      >
        Loading…
      </iframe>
    </Box>
  )
}

export default RDU
